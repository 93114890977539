<template>
  <v-container
    id="register"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="9"
      >
        <v-slide-y-transition appear>
          <v-card
            class="pa-3 pa-md-5 mx-auto"
            light
          >
            <pages-heading class="text-center display-3">

                <v-img
                class="text-center"
                src="/media/gooselogo3.png"
                max-width="250px"
                
                >

                </v-img>
            </pages-heading>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-row no-gutters>
                  <v-col
                    v-for="(section, i) in sections"
                    :key="i"
                    cols="12"
                  >
                    <v-list-item three-line>
                      <v-list-item-icon class="mr-4 mt-5 mt-md-4">
                        <v-icon
                          :large="$vuetify.breakpoint.mdAndUp"
                          :color="section.iconColor"
                          v-text="section.icon"
                        />
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          class="font-weight-light mb-4 mt-3"
                          v-text="section.title"
                        />

                        <v-list-item-subtitle v-text="section.text" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <div class="text-center">
                

                  <div class="my-2" />

                  <div class="text-center grey--text body-1 font-weight-light">
Create an Account below                  </div>
<v-form class="form" @submit.stop.prevent="onSubmit">
               
    <div
          role="alert"
          v-bind:class="{ show: is_error }"
          class="alert fade alert-danger"
        >
          <div class="alert-text">
            {{ err_message }}
          </div>
        </div>



                <v-text-field
                    color="secondary"
                    label="Username..."
                    prepend-icon="mdi-email"
                             v-model="$v.form.username.$model"

                  :state="validateState('username')"



                  />


                  <v-text-field
                    color="secondary"
                    label="Email..."
                    prepend-icon="mdi-email"
                 v-model="$v.form.email.$model"
                             :state="validateState('email')"


                  />

                  <v-text-field
                    class="mb-8"
                     v-model="$v.form.password.$model"
                                :state="validateState('password')"
type="password"
                    color="secondary"
                    label="Password..."
                    prepend-icon="mdi-lock-outline"
                  />

                  <v-checkbox
                    :input-value="true"
                    color="secondary"
                  >
                    <template v-slot:label>
                      <span class="text-no-wrap">I agree to the&nbsp;</span>

                      <a
                        class="secondary--text ml-6 ml-sm-0"
                        href="#"
                      >
                        terms and conditions
                      </a>.
                    </template>
                  </v-checkbox>

                  <pages-btn
                  
                   color="success"
                   
                   
                    type="submit"
            ref="kt_login_signup_submit"
            >

                   
                    Get Started
                  </pages-btn>

</v-form>  
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>


<style lang="sass">
  #register
    .v-list-item__subtitle
      -webkic-line-clamp: initial
      -webkit-box-orient: initial
</style>

<script>
import { mapState } from "vuex";
import { mapGetters, mapActions } from "vuex";
import { REGISTER } from "@/core/services/store/auth.module";
import { LOGOUT } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, required, minLength } from "vuelidate/lib/validators";

export default {

    components: {
      PagesBtn: () => import('./components/Btn'),
      PagesHeading: () => import('./components/Heading'),
    },


  mixins: [validationMixin],
  name: "register",
  data() {
    return {
      is_error: false,
      err_message: "sss",
      // Remove this dummy login info
      form: {
        username: "",
        email: "",
        password: ""
      },

       sections: [
        {
          icon: 'mdi-chart-timeline-variant',
          iconColor: 'primary',
          title: 'The App for eCommerce',
          text: 'We\'ve created the marketing campaign of the website. It was a very interesting collaboration.',
        },
        {
          icon: 'mdi-code-tags',
          iconColor: 'secondary',
          title: 'Fully Featured',
          text: 'We have all the tools you need to connect better with your business partners whether you are a brand or retailer ',
        },
        {
          icon: 'mdi-account-multiple',
          iconColor: 'cyan',
          title: 'Connecting Suppliers to Sellers',
          text: 'There is a great new adventure awaiting you inside.',
        },
      ],
    };
  },

  
  validations: {
    form: {
      username: {
        required
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  methods: {
    ...mapActions([
			'getInviteTotalCount'
		]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        username: null,
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const username = this.$v.form.username.$model;
      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      

      // dummy delay
      setTimeout(() => {
        let invited_supplier = '';
        if (this.$route.query.supplier != undefined && this.$route.query.supplier != '') {
          this.getInviteTotalCount({
            params: "supplier_company="+ this.$route.query.supplier +"&seller_email="+ email
          })
          .then((res) => {
            if(res.data != 0) {
              invited_supplier = this.$route.query.supplier;
            }
            
            // send register request
            this.$store
              .dispatch(REGISTER, {
                email: email,
                password: password,
                username: username,
                invited_supplier: invited_supplier
              })
              .then(() => {
                this.$router.push({ name: "login", params: { register: true } });
              })
              .catch(() => {
                this.is_error = true;
                this.err_message = "Duplicated email or username!";
              });
          });
        }
        else {
          // send register request
          this.$store
            .dispatch(REGISTER, {
              email: email,
              password: password,
              username: username
            })
            .then(() => {
              this.$router.push({ path: "/pages/login", params: { register: true } });
            })
            .catch(() => {
              this.is_error = true;
              this.err_message = "Your Email has already been registered. Please choose a different email or request a password";
            });
        }

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
